
import { defineComponent } from 'vue';
import { CardMedSession, CategorySlider, DurationSetter, Dialog, Snackbar } from '@/components/';
import { CategoriesController, MedSessionsController, UserController } from '@/controller';
import store from '@/store/index';

export default defineComponent({
    name: "Meditations",
    components: { CardMedSession, CategorySlider, DurationSetter, Dialog, Snackbar },
    data(){
        return{
            store,
            medSessions: [] as Array<any>,
            categories: [] as Array<any>,
            user: {} as any,
            durationFilter: 0,
            categoryFilter: -1,
            icons:{
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
            },
            responseState: '',
            snackbar: {
                show: false,
                text: '',
                error: false
            },   
            handleShowNoFreeSessions: false,         
            dontShowAgain: false,
            waitForResponse: false,     
            fadeOutCard: false,
        }
    },
    computed:{
        getFilteredMedSessions(){
            let filtered :any = [];

            if(this.medSessions){
                if(this.categoryFilter >= 0){
                    this.medSessions.forEach((session :any) => {
                        session['categories'].forEach((c:any) => {
                            if(c.uid == this.categoryFilter){
                                filtered.push(session)
                            }                     
                        });
                    });
                    
                } else {
                    filtered = this.medSessions
                }
            
                if(this.durationFilter == 1){
                    filtered = filtered.filter((session : any) => session.duration <= 600);
                } else if (this.durationFilter== 2){
                    filtered = filtered.filter((session : any) => session.duration > 600 &&  session.duration <= 900);
                } else if (this.durationFilter== 3){
                    filtered = filtered.filter((session : any) => session.duration > 900);
                }
            }        
            this.fadeInCards()  
            return filtered
        }, 
    },
    created(){
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        this.fetchData();
    }, 
    methods:{

        handleSetCategoryFilter(category : number){
            if(this.getFilteredMedSessions.length > 0){
                this.fadeOutCard = true;
            }
            this.categoryFilter = category;
            //this.fadeInCards()
        },
        handleSetDurationFilter(duration : number){
            if(this.getFilteredMedSessions.length > 0){
                this.fadeOutCard = true;
            }
            this.durationFilter = duration    
            //this.fadeInCards()  
        },
        fadeInCards(){
            setTimeout(() => {
                this.fadeOutCard = false;
            }, 300)  
        },
        async fetchData(){
            this.user = await UserController.user
            this.medSessions = MedSessionsController.medSessions;
            this.getCategories();
        },
        async getCategories(){
            const res : any = await CategoriesController.getCategories();
            if(res.data.success){
                this.categories = CategoriesController.categories;
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
            }
        },
        handleOpenDetailView(sessionId : any){
            //this.handleGetMedSession();
            this.$router.push('/medsession/'+ sessionId)
        }, 

        handleManageAbo(){
            this.$router.push('/manageabo')
        },
        handleCloseDialog(){
            this.handleShowNoFreeSessions =! this.handleShowNoFreeSessions
        },
        handleDontShowAgain(state:any){
            this.dontShowAgain = state
        },   
        handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error

            setTimeout(() => {
                this.snackbar.show = false
            }, 5000)
        },     
 
                /*async handleGetMedSession(){
            let res : any = await MedSessionsController.getMedSession(this.currentMedSession.uid)
            if(res.data.success){
                this.$router.push('/medsession/'+ res.data.data.uid)
            } else {
                if(res.data.errors[0].field == 'abo' && !this.dontShowAgain){
                    this.handleShowNoFreeSessions = true
                } else{
                    this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)
                }
            }
        },*/
        /*getDisabledCard(statistic:any){           
            if(this.user.freeSessionsLeft >0){
                return false
            } else if (this.user.freeSessionsLeft <= 0 && statistic && statistic.available==1){
                return false
            } else {
                return true
            }
        },*/
    }
})
