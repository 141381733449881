import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "meditations-container" }
const _hoisted_2 = { class: "main-content headline" }
const _hoisted_3 = { class: "main-content" }
const _hoisted_4 = {
  key: 0,
  class: "cards-container cards-col-3"
}
const _hoisted_5 = {
  key: 1,
  class: "no-data-found"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategorySlider = _resolveComponent("CategorySlider")!
  const _component_DurationSetter = _resolveComponent("DurationSetter")!
  const _component_CardMedSession = _resolveComponent("CardMedSession")!
  const _component_Snackbar = _resolveComponent("Snackbar")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('heading_meditations')), 1)
    ]),
    _createVNode(_component_CategorySlider, {
      categories: _ctx.categories,
      onHandleSetCategoryFilter: _ctx.handleSetCategoryFilter
    }, null, 8, ["categories", "onHandleSetCategoryFilter"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DurationSetter, { onHandleSetDurationFilter: _ctx.handleSetDurationFilter }, null, 8, ["onHandleSetDurationFilter"]),
      (_ctx.getFilteredMedSessions.length >0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFilteredMedSessions, (item) => {
              return (_openBlock(), _createBlock(_component_CardMedSession, {
                key: item.uid,
                medSession: item,
                allSessionsAvailable: _ctx.user.abo.allSessionsAvailable == 1 ? true : false,
                class: _normalizeClass({'fadeOutCard': _ctx.fadeOutCard}),
                onClick: (event) => _ctx.handleOpenDetailView(item.uid)
              }, null, 8, ["medSession", "allSessionsAvailable", "class", "onClick"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("p", _hoisted_5, "Keine Meditationen gefunden."))
    ]),
    _createVNode(_component_Snackbar, {
      show: _ctx.snackbar.show,
      text: _ctx.snackbar.text,
      error: _ctx.snackbar.error,
      aboveBottomMenu: true
    }, null, 8, ["show", "text", "error"]),
    _createVNode(_component_Dialog, {
      show: _ctx.handleShowNoFreeSessions,
      title: _ctx.$t('dialog.no_free_sessions_available'),
      text: (_ctx.$t('dialog.dear', {name: _ctx.user.firstName})+ _ctx.$t('dialog.no_free_sessions_text')),
      buttonText: _ctx.$t('button.changeMembership'),
      enableDontShowAgain: true,
      hideCancelButton: true,
      onHandleConfirm: _ctx.handleManageAbo,
      onHandleCancel: _ctx.handleCloseDialog,
      onHandleDontShowAgain: _ctx.handleDontShowAgain
    }, null, 8, ["show", "title", "text", "buttonText", "onHandleConfirm", "onHandleCancel", "onHandleDontShowAgain"])
  ]))
}